<template>
  <el-card style="margin-top: 30px" v-loading="loading">
    <div style="display: flex;justify-content: space-between; margin-bottom: 20px">
      <h3 style="margin-top: 0; margin-bottom: 0">Настройки процедуры</h3>
      <div style="display: flex">

        <import-modal
          entity="raise_workers_ranges_by_bands"
          title="Импорт диапазонов оплаты труда по проф.группам/разрядам и локации"
          :data="{procedure_id: procedure.id}"
          close-on-success
          @import-finished="importFinished"
        >
          <template v-slot:reference>
            <el-button
              size="mini"
              icon="fas fa-file-import"
              @click=""
            >
              Импорт диапазонов
            </el-button>

          </template>
        </import-modal>

        <el-button
          style="margin-left: 20px"
          size="mini"
          icon="fas fa-upload"
          @click="exportToExcel"
        >
          Экспорт пользователей
        </el-button>
      </div>
    </div>


    <div style="margin-bottom: 20px">
      <div>Бюджетный % повышения оплаты труда</div>
      <click-to-edit
        style="width:200px"
        placeholder=""
        v-model="meta.budget_percent_for_raise"
        digits-only
        @input="saveMeta"
      ></click-to-edit>
    </div>



    <div class="smz-table-name">Диапазон % повышения оплаты труда</div>

    <table class="smz-base-table">
      <thead>
      <tr>
        <th style="width: 40%">Итоговая оценка эффективности деятельности</th>
        <th style="width: 20%">Min, %</th>
        <th style="width: 20%">Middle, %</th>
        <th style="width: 20%">Max, %</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="evaluation in evaluations[$companyConfiguration.settings.getEnum('evaluation_types')['TYPE_FINAL_EFFICIENCY']]">
        <td class="smz-base-table-cell-gray">{{ evaluation.name }}</td>
        <td>
          <click-to-edit
            v-if="meta.raise_percent_range"
            placeholder=""
            v-model="meta.raise_percent_range.min[evaluation.id]"
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-if="meta.raise_percent_range"
            placeholder=""
            v-model="meta.raise_percent_range.middle[evaluation.id]"
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-if="meta.raise_percent_range"
            placeholder=""
            v-model="meta.raise_percent_range.max[evaluation.id]"
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
      </tr>
      </tbody>
    </table>



    <div class="smz-table-name" style="margin-top: 30px">Расчет диапазонов рекомендованных процентов повышения оплаты труда</div>

    <div style="display: flex; gap: 20px">
      <user-unique-prop-values-select
        placeholder="Цех"
        style="width: 300px"
        v-model="filters.workshops"
        prop="workshop"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>
      <user-unique-prop-values-select
        placeholder="Должность"
        style="width: 300px"
        v-model="filters.positions"
        prop="position"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>
      <user-unique-prop-values-select
        placeholder="Дирекция"
        style="width: 300px"
        v-model="filters.directions"
        prop="direction"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>
    </div>

    <table
      class="smz-base-table"
      style="margin-top: 20px; width: 100%"
      v-loading="loadingUsers"
    >
      <thead>
      <tr>
        <th>ФИО</th>
        <th>Личный номер</th>
        <th>Дирекция</th>
        <th>Цех</th>
        <th>Должность</th>
        <th>Оклад сотрудника</th>
        <th>Оклад сотрудника * бюджетный % повышения</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="procedureUser in procedureUsers"
      >
        <td>
          <div v-if="procedureUser.historyUser">{{ procedureUser.historyUser.last_name }} {{ procedureUser.historyUser.first_name }} {{ procedureUser.historyUser.middle_name }}</div>
          <div v-else>Нет данных из истории</div>
        </td>
        <td>{{ procedureUser.historyUser ? procedureUser.historyUser.persnum : 'Нет данных из истории' }}</td>
        <td>{{ procedureUser.historyUser ? procedureUser.historyUser.direction : 'Нет данных из истории' }}</td>
        <td>{{ procedureUser.historyUser ? procedureUser.historyUser.workshop : 'Нет данных из истории' }}</td>
        <td>{{ procedureUser.historyUser ? procedureUser.historyUser.position : 'Нет данных из истории' }}</td>
        <td>
          <click-to-edit
            v-model="procedureUser.current_salary"
            :number-format="{decimals: 2, space:true}"
            placeholder=""
            digits-only
            @input="saveProcedureUserSalary(procedureUser)"
          ></click-to-edit>
        </td>
        <td>{{ procedureUser.salary_on_budget_percent_for_raise | numberFormat }}</td>
        <td>
          <el-popconfirm
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Вы уверены, что хотите удалить пользователя?"
            @confirm="deleteProcedureUser(procedureUser)"
          >
            <el-button
              style="margin-right: 10px"
              slot="reference"
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="far fa-trash-alt"
            >
            </el-button>
          </el-popconfirm>
        </td>
      </tr>
      </tbody>
    </table>
    <paginator
      :paginator.sync="paginator"
      @page-changed="getProcedureUsers"
    ></paginator>



    <div class="smz-table-name" style="margin-top: 35px">Диапазон рекомендованных % повышения оплаты труда после корр.фактора</div>

    <table class="smz-base-table">
      <thead>
      <tr>
        <th style="width: 40%">Итоговая оценка эффективности деятельности</th>
        <th style="width: 20%">Min, %</th>
        <th style="width: 20%">Middle, %</th>
        <th style="width: 20%">Max, %</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="evaluation in evaluations[$companyConfiguration.settings.getEnum('evaluation_types')['TYPE_FINAL_EFFICIENCY']]">
        <td class="smz-base-table-cell-gray">{{ evaluation.name }}</td>
        <td>
          <click-to-edit
            v-if="meta.advice_raise_range"
            placeholder=""
            v-model="meta.advice_raise_range.min[evaluation.id]"
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-if="meta.advice_raise_range"
            placeholder=""
            v-model="meta.advice_raise_range.middle[evaluation.id]"
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
        <td>
          <click-to-edit
            v-if="meta.advice_raise_range"
            placeholder=""
            v-model="meta.advice_raise_range.max[evaluation.id]"
            digits-only
            @input="saveMeta"
          ></click-to-edit>
        </td>
      </tr>
      </tbody>
    </table>




    <CompensationProcedureRaiseWorkersRangesByBands
      :procedure="procedure"
      ref="rangesComponent"
      style="margin-top: 35px"
    ></CompensationProcedureRaiseWorkersRangesByBands>


    <div style="margin-top:35px; display: flex; flex-direction: row-reverse; gap: 20px">
      <el-button
        size="mini"
        type="primary"
        icon="fas fa-calculator"
        @click="recountProcedure(()=> {getProcedureUsers()})"
      >
        Внести изменения
      </el-button>

      <el-popover
        placement="top"
        trigger="click"
      >
        <el-button
          slot="reference"
          style="margin-left:10px"
          class="table-action-button danger"
          type="text"
          round
          size="mini"
          icon="fas fa-info-circle"
        ></el-button>

        <div>Чтобы внесенные изменения отобразились в процедуре руководителей нажмите на кнопку</div>
      </el-popover>
    </div>

  </el-card>
</template>

<script>

import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import Paginator from "@/components/Paginator.vue";
import {ProcedureEditMixin} from "@/componentsCompany/smz/compensation/mixins/ProcedureEditMixin";
import UserUniquePropValuesSelect from "@/components/filters/users/UserUniquePropValuesSelect.vue";
import {downloader} from "@/mixins/downloader";
import ImportModal from "@/components/exportImport/ImportModal.vue";
import CompensationProcedureRaiseWorkersRangesByBands
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureRaiseWorkersRangesByBands.vue";

export default {
  name: "RaiseWorkersTemplate",
  components: {
    CompensationProcedureRaiseWorkersRangesByBands,
    ImportModal, UserUniquePropValuesSelect, Paginator, ClickToEdit},
  mixins: [paginator, sorter, ProcedureEditMixin, downloader],
  props: {
    procedure: {type: Object, required: true},
  },

  data() {

    return {
      loading: false,
      loadingUsers: false,

      evaluations: {},
      meta: {},

      filters:{
        workshops:[],
        positions:[],
        directions:[],
      },
      procedureUsers: [],


    }
  },

  mounted() {
    this.getEvaluations();
    this.getMeta();
    this.getProcedureUsers();
  },
  methods: {

    filtersChanged(){
      this.getProcedureUsers();
    },

    getProcedureUsers(){
      this.loadingUsers = true;
      requestSender('get', 'compensation-procedure-raise-workers/get-procedure-users', {
        procedure_id: this.procedure.id,
        ...this.filters,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        expand: 'historyUser'
      })
        .then(({data, paginator, sorter}) => {
          this.procedureUsers = data.procedure_users;
          this.paginatorFill(paginator);
          // this.sorterFill(sorter);
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },


    saveProcedureUserSalary(procedureUser){
      this.loadingUsers = true;
      requestSender('post', 'compensation-procedure-raise-workers/update-procedure-user-salary', {
        procedure_id: this.procedure.id,
        user_id: procedureUser.user_id,
        salary: procedureUser.current_salary,
      })
        .finally(() => {
          this.loadingUsers = false;
        });
    },

    deleteProcedureUser(procedureUser){
      this.loadingUsers = true;
      requestSender('post', 'compensation-procedure-raise-workers/delete-procedure-user', {
        procedure_id: this.procedure.id,
        user_id: procedureUser.user_id,
      })
        .finally(() => {
          this.getProcedureUsers();
        });
    },


    exportToExcel() {
      this.downloadFile(
        false,
        this.$api.exportImport.export,
        {
          entity: 'raise_workers_users',
          data: {
            procedure_id: this.procedure.id,
            ...this.filters,
          }}
      )
    },

    importFinished(){
      this.$refs.rangesComponent.getSalaryRanges();
    },

  },


}
</script>

<style lang="scss">


</style>